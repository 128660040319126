import React from "react"
import PropTypes from "prop-types"
import * as styles from "./VideoCard-tw-styles"
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage"
import RichTextModel from "../../RichTextModel/RichTextModel"
import PlayIcon from "../../../assets/icons/PlayIcon"
import useModal from "../../../hooks/useModal"
import YoutubeModal from "./YoutubeModal"

function VideoCard(props) {
    const { thumbnailImage, content, youtubeVideoUrl, gridClasses } = props
    const style = styles.defaultStyle
    const { isOpen, openModal, closeModal, modalRef } = useModal()
    const onClickHandler = () => {
        openModal()
    }
    const validYoutubeUrl =
        youtubeVideoUrl && youtubeVideoUrl.match(/https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]{11}(&[^\s]*)?$/)
    if (!validYoutubeUrl) return
    const videoId = youtubeVideoUrl.match(/[\w-]{11}/)[0]

    return (
        <div className={`${style.wrapper} ${gridClasses}`}>
            {!!thumbnailImage && (
                <div className={style.thumbnailImageWrapper}>
                    <div className="relative">
                        <ResponsiveImage contentfulImagesComponent={thumbnailImage} className={style.thumbnailImage} />
                        <div className={style.thumbnailOverlay} onClick={onClickHandler}>
                            <PlayIcon />
                        </div>
                    </div>
                </div>
            )}
            {!!content && <RichTextModel richText={content} style={style.content} />}
            <YoutubeModal isOpen={isOpen} onClose={closeModal} modalRef={modalRef} videoId={videoId} />
        </div>
    )
}

export default VideoCard

VideoCard.propTypes = {
    thumbnailImage: PropTypes.object,
    content: PropTypes.object,
    youtubeVideoUrl: PropTypes.string,
    gridClasses: PropTypes.string,
}
